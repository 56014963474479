import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  debounce,
  CircularProgress,
  Box,
  Chip,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useCallback, useEffect, useState } from "react";
import PendingEventsRow from "../admin/PendingEventsTable.row";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    fontWeight: "bold",
  },
}))(TableCell);

interface CustomTableProps {
  headers: any[];
  content: any[];
  total: number;
  pageSize: number;
  pageNb: number;
  onChangePage: Function;
  onChangeRowsPerPage: Function;
  onChangeSearch: Function;
  onChangeStatus: Function;
  disableSearch?: boolean;
  pageSizeOptions?: number[];
  onChangeSelected: Function;
  onSave: Function;
  onDelete: Function;
  isLoading: boolean;
}

function PendingEventsTable({
  headers,
  content,
  total,
  pageSize,
  pageNb,
  onChangePage,
  onChangeRowsPerPage,
  onChangeSearch,
  onChangeStatus,
  disableSearch = false,
  pageSizeOptions = [5, 10, 20],
  onChangeSelected,
  onSave,
  onDelete,
  isLoading,
}: CustomTableProps) {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<number>();
  const [status, setStatus] = useState<'pending' | 'accepted' | 'rejected'>();

  const changeSearch = useCallback(
    debounce((name: string) => {
      onChangeSearch(name);
    }, 200),
    []
  );

  useEffect(() => {
    changeSearch(search);
  }, [search, changeSearch]);

  useEffect(() => {
    onChangeSelected(selected);
  }, [selected, onChangeSelected]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    onChangePage(newPage);
    setSelected(undefined);
  }, [onChangePage]);

  const handleChangeRowsPerPage = useCallback((
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChangeRowsPerPage(+event.target.value);
  }, [onChangeRowsPerPage]);

  const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setSelected(-1);
  }, []);

  const handleChangeSelected = useCallback((rowNb: number) => {
    setSelected(rowNb);
  }, []);
  
  useEffect(() => {
    onChangeStatus(status);
  },[onChangeStatus, status])

  return (
    <Paper sx={{ m: 2, p: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Chip
          label="pending"
          sx={{
            color: "background.paper",
            m: 1,
            backgroundColor: "#b884e3",
            opacity: status === "pending" ? "default" : 0.5,
            '&:hover': {
              backgroundColor: "#b884e3",
              opacity: 0.8
            }
          }}
          onClick={() => setStatus((s) => s === "pending" ? undefined : "pending")}
        />
        <Chip
          label="accepted"
          sx={{
            color: "background.paper",
            m: 1,
            backgroundColor: "#4caf50",
            opacity: status === "accepted" ? "default" : 0.5,
            '&:hover': {
              backgroundColor: "#4caf50",
              opacity: 0.8
            }
          }}
          onClick={() => setStatus((s) => s === "accepted" ? undefined : "accepted")}
        />
        <Chip
          label="rejected"
          sx={{
            color: "background.paper",
            m: 1,
            backgroundColor: "#f50057",
            opacity: status === "rejected" ? "default" : 0.5,
            '&:hover': {
              backgroundColor: "#f50057",
              opacity: 0.8
            }
          }}
          onClick={() => setStatus((s) => s === "rejected" ? undefined : "rejected")}
        />
      </Box>
      <TableContainer
        sx={{
          border: (theme) => `solid 1px ${theme.palette.grey[300]}`,
          borderRadius: (theme) => theme.spacing(0.5),
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <StyledTableCell key={index} align="center">
                  {header.title}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={11}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={80} />
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              content.map((row, index) => (
                <PendingEventsRow
                  key={index}
                  headers={headers}
                  index={index}
                  content={row}
                  onChangeSelected={handleChangeSelected}
                  onSave={onSave}
                  onDelete={onDelete}
                  selected={selected === index}
                />
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {!disableSearch && (
                <TableCell>
                  <TextField
                    size="small"
                    label="Search"
                    variant="outlined"
                    color="secondary"
                    onChange={handleChangeSearch}
                  />
                </TableCell>
              )}
              <TablePagination
                count={total}
                page={pageNb}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={pageSizeOptions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default PendingEventsTable;
